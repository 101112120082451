import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import Modal from "../Modal/Modal";

import logo from "./GratitudeLogo.png";

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="header">
      {open && <Modal closeModal={() => setOpen(false)} />}
      <div className="header_logo">
        <Link to="/">
          <img className="logo" src={logo} alt="logo" />
        </Link>
        <div>
          <p className="title">GRATITUDE WALL</p>
          <p className="sub_title">Hand picked content for you</p>
        </div>
      </div>
      <div onClick={() => setOpen(true)} className="app_btn">
        <p>USE APP</p>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import MetaDataContainer from "./Components/MetaDataContainer/MetaDataContainer";
import UnsubscribePage from "./Components/Unsubscribe/Unsubscribe";
import GratitudePosts from "./Components/GratitudePosts/GratitudePosts";
import FullPost from "./Components/FullPost/FullPost";
import UserProfile from "./Components/UserProfile/UserProfile";
import Error404 from "./Components/Error404/Error404";

const App = () => {
  return (
    <div className="app">
      <Router>
        <MetaDataContainer></MetaDataContainer>
        <Switch>
          <Route exact path='/' component={() => {
            window.location.replace("https://updeed.co/");
            return null;
          }}/>
          <Route exact path='/gratitudewall' component={() => {
            window.location.replace("https://updeed.co/gratitude-wall/");
            return null;
          }}/>
          {/* <Route exact path="/" component={GratitudePosts} />
          <Route exact path="/gratitudewall" component={GratitudePosts} /> */}
          <Route exact path="/post/:postId" component={FullPost} />
          <Route exact path="/profile/:profileId" component={UserProfile} />
          <Route exact path="/unsubscribe" component={UnsubscribePage} />
          <Route exact path="/404" component={Error404} />
          <Route render={() => <Redirect to={{ pathname: "/" }} />} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;

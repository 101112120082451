import React from "react";
import DefaultPic from "../../assets/svg/default_pic.svg";
import "./PostMention.css";

function PostMention({ mention }) {
  return (
    <div className="mention_container">
      <div>
        <div className="mention_profilePic">
          {mention.profilePic === "" || mention.profilePic === "url" ? (
            <img src={DefaultPic} alt="" />
          ) : (
            <img src={mention.profilePic} alt="" />
          )}
        </div>
      </div>

      <div className="mention_info">
        <div className="mention_name">
          <p>{`${mention.firstName} ${mention.lastName}`}</p>
        </div>
        <div className="mention_appr">{`${mention.appreciationsCount} Appreciations`}</div>
      </div>
    </div>
  );
}

export default PostMention;

import React, { useState, useEffect } from "react";
import Card from "../Card/Card";
import "./GratitudePosts.css";
import axios from "axios";
import Masonry from "react-masonry-css";
import Header from "../Header/Header";
import { GET_GRATITUTDE_POSTS_URL } from "../../APIEndPoints";
import MetaDataContainer from "../MetaDataContainer/MetaDataContainer";
import GratitudeLogo from "../Header/GratitudeLogo.png"

function GratitudePosts() {
  const [posts, setPosts] = useState([]);
  const [isEnd, setIsEnd] = useState(false);

  const _getGratitudePosts = async (bottomSeqId, take) => {
    const queryParams = {};
    if(bottomSeqId)
      queryParams.bottomSeqId=bottomSeqId;
    if(take)
      queryParams.take=take;
    const { data: result } = await axios.get(
      GET_GRATITUTDE_POSTS_URL,
      {
        params: queryParams
      }
    );
    return result.data;
  };

  useEffect(() => {
    _getGratitudePosts(0, 22).then((posts) => setPosts(posts));
  }, []);

  const seeMorePosts = async () => {
    const bottomSeqId = posts[posts.length-1].seqId;
    const morePosts = await _getGratitudePosts(bottomSeqId, 12);
    const allPosts = [...posts];
    allPosts.push(...morePosts);

    if (morePosts.length > 0) {
      setPosts(allPosts);
    } else {
      setIsEnd(!isEnd);
    }
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <div className="wrapper">
      <MetaDataContainer
        quote={undefined}
        title="Gratitude Wall | UPDEED"
        description="Gratitude Wall by UPDEED. Curated and recommended stories on UPDEED by Change Makers from across the world!"        
        image={GratitudeLogo}
        hashtag={undefined}
      />
      <div className="container">
        <Header />
      </div>
      <div className="body_container">
        <div className="post_container">
          <div>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="gratitudewall"
              columnClassName="gratitudewall_column"
            >
              {posts.length > 0 &&
                posts.map((post) => (
                  <Card
                    key={post.id}
                    postId={post.id}
                    firstName={post.postedBy.firstName}
                    lastName={post.postedBy.lastName}
                    postText={post.media.postText}
                    profilePic={post.postedBy.profilePic}
                    text={post.postText}
                    cardImages={post.media}
                    trend={post.hashTags}
                    timeStamp={post.postedDate}
                  />
                ))}
            </Masonry>
          </div>
        </div>
      </div>
      {!isEnd ? (
        <div className="load_posts">
          <p onClick={seeMorePosts}>See more posts</p>
        </div>
      ) : (
        <div className="load_posts">
          <p>No more Posts</p>
        </div>
      )}
    </div>
  );
}

export default GratitudePosts;

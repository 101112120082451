import React from "react";
import LikeIcon from "../../assets/img/like.png";
import "./PostComments.css";

function PostComment({ comment }) {
  return (
    <div className="comment_container">
      <p className="comment_name">
        {comment.commentedUser.firstName} {comment.commentedUser.lastName}
      </p>
      <p className="comment">{comment.commentText}</p>
      <div>
        <img className="like_icon" src={LikeIcon} alt="like_icon" />
      </div>
    </div>
  );
}

export default PostComment;

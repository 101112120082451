import React, { useState, useEffect, useRef } from "react";
import "./ImageSlider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

function ImageSlider({ cardImages, isAuto }) {
  const [current, setCurrent] = useState(0);
  const length = cardImages.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const getMediaElt = (slide) => {
    if (slide.isImage === true) return <img src={slide.link} alt="" />;
    if (slide.link.includes("mp4"))
      return (
        <video className="video" controls muted>
          <source src={slide.link} type="video/mp4"></source>
        </video>
      );
    return <a href={slide.link}>{slide.link}</a>;
  };

  function useInterval(callback, delay) {
    const savedCallBack = useRef();

    useEffect(() => {
      savedCallBack.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallBack.current();
      }
      if (delay != null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(
    () => {
      nextSlide();
    },
    isAuto ? 6000 : null
  );

  if (!Array.isArray(cardImages) || cardImages.length <= 0) {
    return null;
  }

  return (
    <div className="media_element">
      {cardImages.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
          >
            {index === current && getMediaElt(slide)}
          </div>
        );
      })}
      {cardImages.length === 1 ? (
        ""
      ) : (
        <>
          {" "}
          <FontAwesomeIcon
            className="left_arrow"
            onClick={prevSlide}
            icon={faChevronCircleLeft}
            size="2x"
          />
          <FontAwesomeIcon
            className="right_arrow"
            onClick={nextSlide}
            icon={faChevronCircleRight}
            size="2x"
          />
        </>
      )}
    </div>
  );
}

export default ImageSlider;

import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import MetaDataContainer from "../MetaDataContainer/MetaDataContainer";
import "./UserProfile.css";
import { GET_POST_BY_ID_URL } from "../../APIEndPoints";
import { GET_COMMENTS_BY_ID_URL } from "../../APIEndPoints";
import { GET_USER_PROFILE_BY_ID_URL } from "../../APIEndPoints";
import axios from "axios";
import UpdeedHeader from "../Header/UpdeedHeader";
import Header from "../Header/Header";
import DefaultPic from "../../assets/svg/default_pic.svg";
import Modal from "../Modal/Modal";
import Error404 from "../Error404/Error404";

function UserProfile(props) {
  const userId = props.match.params.profileId;
  const [profile, setProfile] = useState(null);
  const [open, setOpen] = useState(false);

  const _getUserById = async (id) => {
    const ENDPOINT = GET_USER_PROFILE_BY_ID_URL.replace(/##user_id##/g, id);
    const { data: result } = await axios.get(
      ENDPOINT
    );
    return result.data;
  };

  useEffect(() => {
    _getUserById(userId).then((profileInfo) => setProfile(profileInfo));
  }, []);


  const toggle = () => {
    setOpen(!open);
  };

  return (
          <div className="wrapper">
            {open && <Modal closeModal={toggle} />}
            {profile && <MetaDataContainer
              quote={profile.profileName}
              title={!!profile.profileName ? profile.profileName : undefined}
              description={profile.profileName}        
              image={!!profile.profilePic  ? profile.profilePic : undefined}
              hashtag={undefined}
            />}
            <div className="container">
             <UpdeedHeader />
            </div>
            {profile &&
            <div className="body_container">
              <div className="profile_card">
                <div className="profile_img_wrapper">
                  <img className="profile_img" src={profile.profilePic ? profile.profilePic : DefaultPic} alt="profile-pic" />
                </div>
                <div className="profile_name" style={{fontSize: '16px', marginTop: '5px'}}>
                  <h2>
                    {profile.firstName} {profile.lastName}
                  </h2>
                </div>
                <div style={{fontSize: '20px'}}>
                  <h5>{profile.profileName}</h5>
                </div>
      
                <div className="profile_stats">
                  <div className="profile_stats_item">
                    <h5 className="profile_stats_item_header">Appreciations</h5>
                    <h6 className="profile_stats_item_content">{profile.appreciationsCount ? profile.appreciationsCount : 0}</h6>
                  </div>
      
                  <div className="profile_stats_item">
                    <h5 className="profile_stats_item_header">Mentions</h5>
                    <h6 className="profile_stats_item_content">{profile.mentionsCount ? profile.mentionsCount : 0}</h6>
                  </div>
                  <div className="profile_stats_item">
                    <h5 className="profile_stats_item_header">Associations</h5>
                    <h6 className="profile_stats_item_content">{profile.associationsCount ? profile.associationsCount : 0}</h6>
                  </div>
                  <div className="profile_stats_item">
                    <h5 className="profile_stats_item_header">Awards</h5>
                    <h6 className="profile_stats_item_content">{profile.nominationsCount ? profile.nominationsCount : 0}</h6>
                  </div>
                </div>

                <div className="view_profile_btn_div">
                  <button 
                    className="view_profile_btn"
                    onClick={() => setOpen(true)}
                  >View Profile</button>
              </div>
              </div>
            </div>}
          </div>

  );
}





export default UserProfile;

import React, { useState } from "react";
import "./UpdeedHeader.css";
import { Link } from "react-router-dom";
import UpdeedLogo from "./UpdeedLogo.png";
import Modal from "../Modal/Modal";

function UpdeedHeader() {
  const [open, setOpen] = useState(false);

  return (
    <div className="updeed_header">
      {open && <Modal closeModal={() => setOpen(false)} />}
      <div className="header_logo">
        <Link to="/">
          <img className="logo" src={UpdeedLogo} alt="logo" />
        </Link>
        <div>
          <p className="updeed_title">UPDEED</p>
        </div>
      </div>
      <div onClick={() => setOpen(true)} className="updeedapp_btn">
        <p>USE APP</p>
      </div>
    </div>
  );
}

export default UpdeedHeader;

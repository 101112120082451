import React, { useEffect } from "react";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGooglePlay, faApple } from "@fortawesome/free-brands-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/img/Logo.png";
import PositiveSpace from "../../assets/img/Positive-Space-04.png";

function Modal(props) {

  useEffect(() => {
    console.log('HELLO');
    console.log(window);
    const closeOnEscPress = (e) => {
      if(e.keyCode === 27){
       props.closeModal();
      }
    }
    window.addEventListener('keydown', closeOnEscPress)
  return () => window.removeEventListener('keydown', closeOnEscPress)
},[])
  return (
    <div className="modal">
      <div className="modal_view">
        <FontAwesomeIcon
          onClick={props.closeModal}
          className="close"
          icon={faTimesCircle}
        />
        <div className="modal_top">
          <p className="modal_text">
            Like what you see?<br></br> You can be a part of the change too!
          </p>
        </div>

        <div className="modal_center">
          <div className="center_box">
            <p className="center_text">Join</p>
            <p className="title_text">UPDEED</p>
          </div>
          <div className="center_box">
            <p className="center_text">Available now on </p>
            <a href="https://apps.apple.com/us/app/updeed/id1543932286">
              <FontAwesomeIcon className="app_link" icon={faApple} size="2x" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=co.updeed.updeed">
              <FontAwesomeIcon
                className="app_link"
                icon={faGooglePlay}
                size="2x"
              />
            </a>
          </div>
        </div>
        <div className="modal_bottom">
          <div className="brand">
            <img src={Logo} alt="" />
            <p className="title_text">UPDEED</p>
            <p className="supertext">Do. Appreciate. Connect.</p>
          </div>
          <div className="tag">
            <div className="tag_border">
              <p>A positive space on the internet</p>
            </div>
            {/* <img src={PositiveSpace} alt="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;

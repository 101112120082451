import React from "react";

function TimeFormatter({ timeStamp }) {
  const date = new Date(timeStamp);
  const time = date.getTime() / 1000;
  const now = new Date().valueOf();
  const age = (now - time * 1000) / 1000;

  let duration = "";
  const durationInSeconds = { days: 86400, hours: 3600, mins: 60, secs: 1 };
  for (const span in durationInSeconds) {
    const interval = age / durationInSeconds[span];
    if (interval > 1) {
      duration += Math.round(interval, 0) + " " + span + " " + "ago";
      break;
    }
  }

  return (
    <div>
      <p>{duration}</p>
    </div>
  );
}

export default TimeFormatter;

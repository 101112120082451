import React from 'react'

function Error404() {
    return (
        <div>
            404 Error. Post Doesn't exist or was deleted
        </div>
    )
}

export default Error404;

import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import MetaDataContainer from "../MetaDataContainer/MetaDataContainer";
import "./FullPost.css";
import ClapsIcon from "../../assets/svg/claps.svg";
import CommentsIcon from "../../assets/svg/comments.svg";
import { GET_POST_BY_ID_URL } from "../../APIEndPoints";
import { GET_COMMENTS_BY_ID_URL } from "../../APIEndPoints";
import { GET_USER_PROFILE_BY_ID_URL } from "../../APIEndPoints";
import axios from "axios";
import PostComment from "./PostComment";
import ImageSlider from "../ImageSlider/ImageSlider";
import PostMention from "./PostMention";
import TimeFormatter from "../TimeFormatter/TimeFormatter";
import UpdeedHeader from "../Header/UpdeedHeader";
import Header from "../Header/Header";
import DefaultPic from "../../assets/svg/default_pic.svg";
import Modal from "../Modal/Modal";
import Error404 from "../Error404/Error404";

function FullPost(props) {
  const postId = props.match.params.postId;
  const [post, setPost] = useState(null);
  const [postMentions, setPostmentions]=useState([]);
  const [comments, setComments] = useState([]);
  const [open, setOpen] = useState(false);

  const _getPostById = async (id) => {
    const { data: result } = await axios.get(`${GET_POST_BY_ID_URL}/${id}`);
    if (result.status === 404) {
      console.log('YEAAAYY TRIGERRED');
      return props.history.push("/404");
    }

    //fetch appreciations for each mentioned user in the post
    const mUsersInfo = await Promise.all(
      result.data[0].mentions?.map((user) => _getUsersById(user.userId))
    );

    result.data[0].mentions = mUsersInfo;

    return result.data[0];
  };

  const _getCommentsById = async (id, skip, take) => {
    const { data: result } = await axios.get(
      `${GET_COMMENTS_BY_ID_URL}?postId=${id}&skip=${skip}&take=${take}`
    );
    return result.data;
  };

  const _getUsersById = async (id) => {
    const ENDPOINT = GET_USER_PROFILE_BY_ID_URL.replace(/##user_id##/g, id);
    const { data: result } = await axios.get(
      ENDPOINT
    );
    return result.data;
  };

  useEffect(() => {
    _getPostById(postId).then((post) => {
      setPost(post);
      setPostmentions(postMentions);
    });
  }, []);

  useEffect(() => {
    _getCommentsById(postId, 0, 3).then((comments) => setComments(comments));
  }, []);

  const loadMoreComments = async () => {
    const moreComments = await _getCommentsById(postId, comments.length, 3);
    const allComments = [...comments];
    allComments.push(...moreComments);

    if (moreComments.length > 0) setComments(allComments);
  };




  const hr_line = (
    <div className="line_divide">
      <hr></hr>
    </div>
  );


  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
    {post &&
    <div className="wrapper">
      {open && <Modal closeModal={toggle} />}
      <MetaDataContainer
        quote={post.post_heading}
        title={!!post.post_heading ? post.post_heading : undefined}
        description={post.post_text}        
        image={!!post.media && post.media.length > 0 ? post.media[0].link : undefined}
        hashtag={
              !!post.hashtag && post.hashtag.length > 0
                ? post.hashtag
                    .map((item) => {
                      return item.hashtag.hashTagName;
                    })
                    .join(" ")
                : undefined
            }
      />
      <div className="container">
        {post.is_gratitude === true ? <Header /> : <UpdeedHeader />}
      </div>
      <div className="body_container">
        <div className="fullpost">
          <div className="fullpost_media">
            <div className="fullpost_image">
              {!!post.media && post.media.length > 0 ? 
              <ImageSlider cardImages={post.media} /> : ""}
            </div>
          </div>
          <div className="fullpost_content">
          <>
          <div className="user_container">
            <div>
              <div onClick={() => setOpen(true)} className="user_profilePic">
                {post.postedBy &&
                post.postedBy.profilePic !== "" &&
                post.postedBy.profilePic !== "url" ? (
                  <img src={post.postedBy.profilePic} alt="" />
                ) : (
                  <img src={DefaultPic} alt="" />
                )}
              </div>
            </div>

            <div className="user_info">
              <div onClick={() => setOpen(true)} className="user_name">
                {post.postedBy ? (
                  <p>{`${post.postedBy.firstName} ${post.postedBy.lastName}`}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="user_timestamp">
                <TimeFormatter timeStamp={post.posted_date} />
              </div>
            </div>
          </div>
        </>
            <>
              <div className="post_text">
                <p>{post.post_text}</p>
                <hr className="hr_line"></hr>
              </div>
            </>
            <>
              <div className="box_container">
                <div className="box">
                  <img onClick={() => setOpen(true)} src={ClapsIcon} alt="claps_icon" />
                  <p className="box_text">{`${
                    post.claps === null ? 0 : post.claps
                  } claps`}</p>
                </div>
                <div className="box">
                  <img
                    onClick={() => setOpen(true)}
                    src={CommentsIcon}
                    alt="comments_icon"
                  />
                  <p className="box_text">{`${
                    post.comments === null ? 0 : post.comments
                  } comments`}</p>
                </div>
              </div>
            </>
            <div className="post_comments">
              {comments.map((comment) => {
                return <PostComment comment={comment} key={comment.id} />;
              })}
            </div>
            <div
              onClick={loadMoreComments}
              className={comments.length < post.comments ? "load_more" : "blank"}
            >
              <p>Load More Comments</p>
            </div>
            {hr_line}
            <div className="people_mentioned">
              {post.mentions && post.mentions.length > 0 ? (
                <p>{`${post.mentions.length} people mentioned`}</p>
              ) : (
                ""
              )}
            </div>
            <div className="mentions">
            {postMentions.length > 0 &&
              postMentions.map((mention) => {
                console.log(mention);
                return <PostMention mention={mention} key={mention.id} />;
              })}
            </div>
          </div>
        </div>
        <Link className="redirect_link" to="/gratitudewall">
          See more posts
        </Link>
      </div>
    </div>}
    </Fragment>
  );
}

export default FullPost;

import React, { useState } from 'react';
import qs from 'qs';
import "./Unsubscribe.css";
import axios from "axios";
import Header from "../Header/Header";
import { UNSUBSCRIBE_NOTIFICATIONS } from "../../APIEndPoints";
import GratitudeLogo from "../Header/GratitudeLogo.png"
import MetaDataContainer from "../MetaDataContainer/MetaDataContainer";
import mailIcon from "../../assets/img/mail.png";


export default function Unsubscribe(props) {
    const [unsubscribed, setUnsubscribed] = useState(false);
    const [error, setError] = useState(false);
    const queryParams = qs.parse(props.location.search.replace(/\?/g, ''));
    const token = queryParams?.token;
    const subscribedStyles = { backgroundColor: '#f5f5f5', color: '#0abf53' };
    const unsubscribedStyles = { backgroundColor: '#0abf53', color: '#f5f5f5',  };

    const unsubscribeNotifications = async () => {
        try {
            setError(false);
            if(!token) {
                setError('user authentication token missing')
                return;
            }
            const response = await axios.patch(UNSUBSCRIBE_NOTIFICATIONS, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if(response?.data?.status!=200) {
                setError(response?.data?.message);
                return;
            }
            setUnsubscribed(true);
        }
        catch(error) {
            console.log(error);
            setError('something went wrong. Please try again');
            return;
        }

        setUnsubscribed(true);
    };

    return  (
        <div className="wrapper">
          <MetaDataContainer
            quote={undefined}
            title="Gratitude Wall | UPDEED"
            description="Gratitude Wall by UPDEED. Curated and recommended stories on UPDEED by Change Makers from across the world!"        
            image={GratitudeLogo}
            hashtag={undefined}
          />
            <div className="container">
                <Header />
            </div>
            <div id="unsubscribe">
                <div>
                    <img id="unsubscribe-mail-icon" src={mailIcon} alt="mail-icon" />
                </div>
                {unsubscribed &&<p className="title">Unsubcribed Successfully</p>}
                
                {!unsubscribed &&
                    <p className="sub_title">We are sorry to see you go! Please click confirm to unsubscribe</p>
                }
                <div >{error && <p id="error-div">{error}</p>}</div>
                <div 
                    className="app_btn" 
                    onClick={unsubscribeNotifications}
                    style={unsubscribed ? unsubscribedStyles : subscribedStyles}
                >
                    <p>
                        { !unsubscribed ? 'Confirm' : 'Done' }
                    </p>
                </div>
            </div>
        </div>
    );
}